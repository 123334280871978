<template>
  <v-app>
    <v-main class="align-center bg-light">
      <v-container>
        <v-row no-gutters justify="center" align="center">
          <v-col cols="12">
            <div class="text-center mb-6">
              <img src="@/assets/img/logo-dark.png" alt="tema" />
            </div>
          </v-col>
          <v-col sm="7" lg="5" xl="3">
            <v-card class="pa-7 mb-6" elevation="5">
              <div class="text-h4 text-center mb-5">Авторизация</div>
              <v-form ref="form">
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                  outlined
                  color="secondary"
                  type="email"
                />
                <v-text-field
                  v-model="password"
                  :rules="passwordRules"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  label="Пароль"
                  outlined
                  required
                  color="secondary"
                  @click:append="showPassword = !showPassword"
                />
                <div class="pt-3">
                  <v-btn
                    block
                    x-large
                    color="accent"
                    elevation="2"
                    @click="submit"
                  >
                    Войти
                  </v-btn>
                </div>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      passwordRules: [
        (v) => !!v || "Введите пароль",
        (v) => (v && v.length >= 6) || "Пароль должен быть не менее 6 символов",
      ],
      emailRules: [
        (v) => !!v || "Введите E-mail",
        (v) => /.+@.+\..+/.test(v) || "E-mail введен некорректно",
      ],
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("admin/login", {
          email: this.email,
          password: this.password,
        });
      }
    },
  },
};
</script>
